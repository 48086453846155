const dataCollected = 200;
const noSpeechPercentile = 0.2;
const noSpeechOffset = 0;
const speechPercentile = 0.2;
const speechOffset = 5;
const redemptionFrames = 1;

const startDetect = 5000;
const detectInterval = 100;
const maxAudioLength = 25000;

export default {
    dataCollected,
    noSpeechPercentile,
    noSpeechOffset,
    speechPercentile,
    speechOffset,
    redemptionFrames,
    startDetect,
    detectInterval,
    maxAudioLength,
}